<template>
  <div class="a-btn-pdf">
    <v-btn
      color="white"
      rounded
      outlined
      :loading="loading"
      @click="generatePDF"
    >
      {{ $t("generate_pdf") }}
    </v-btn>
    <v-overlay :value="loading" color="#fff" :opacity="0.95">
      <v-progress-circular
        indeterminate
        color="secondary"
        size="64"
      ></v-progress-circular>
      <div class="text-h4 black--text mt-5">{{ $t("generating_pdf") }}</div>
    </v-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as Utils from "@/utils";

export default {
  name: "a-btn-pdf",
  props: {
    config: {
      type: Object
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async generatePDF() {
      this.loading = true;
      const scale_canvas = 2; // Aumenta la calidad de la imagen capturada
      const orientation = this.config.orientation || "landscape";
      let pdf = new jsPDF(orientation, "mm", "a4");

      if (this.config.elements) {
        for (const element of this.config.elements) {
          const area = document.getElementById(element.id);

          // Mostrar temporalmente el área si está oculta
          let currentElement = area;
          while (currentElement) {
            let display = getComputedStyle(currentElement).display;
            let max_height = getComputedStyle(currentElement).maxHeight;
            if (display === "none") {
              currentElement.dataset.original_display = display;
              currentElement.style.display = "block";
            }
            currentElement.dataset.original_max_height = max_height;
            currentElement.style.maxHeight = "none";
            currentElement = currentElement.parentElement;
          }

          // Añadir un delay para asegurar que el área se muestra correctamente
          await new Promise(resolve => setTimeout(resolve, 500));

          let has_header = false;
          // Si es el primer elemento, añadir el header
          if (element.id === this.config.elements[0].id) {
            has_header = true;
            pdf = await this.generateHeaderPDF(pdf);
          }

          if (element.is_table) {
            pdf = await this.generateTablePDF(
              area,
              scale_canvas,
              pdf,
              has_header
            );
          } else {
            pdf = await this.generateElementPDF(
              area,
              scale_canvas,
              pdf,
              has_header
            );
          }

          // Restaurar el estilo display si existía
          currentElement = area;
          while (currentElement) {
            if (currentElement.dataset.original_display) {
              currentElement.style.display =
                currentElement.dataset.original_display;
              delete currentElement.dataset.original_display;
            }
            currentElement.style.maxHeight =
              currentElement.dataset.original_max_height;
            currentElement = currentElement.parentElement;
          }

          pdf.addPage();
        }
        pdf.deletePage(pdf.internal.getNumberOfPages()); // Eliminar la última página vacía
      }

      pdf.save(this.config.filename);
      this.loading = false;
    },
    async generateHeaderPDF(pdf) {
      const pdf_width = pdf.internal.pageSize.getWidth() - 10; // Margen de 10mm a cada lado
      //const pdf_height = pdf.internal.pageSize.getHeight() - 10; // Margen de 10mm a cada lado

      // Añadir el header al PDF
      // Crea un área con el contenido del header, un flex con nombre "header" y fecha de hoy
      const area = document.createElement("div");
      area.id = "header-pdf";
      area.style.display = "flex";
      area.style.justifyContent = "space-between";
      area.style.alignItems = "center";
      area.style.padding = "10px";
      area.style.marginBottom = "10px";
      area.style.backgroundColor = "#1576c7";

      // Crea los elementos de dentro
      const names = document.createElement("div");
      names.style.fontWeight = "bold";
      names.style.fontSize = "20px";
      names.style.color = "white";
      names.textContent =
        this.config.hotel_name + " - " + this.config.section_name;

      const date = document.createElement("div");
      date.style.fontWeight = "bold";
      date.style.fontSize = "20px";
      date.style.color = "white";
      date.style.marginRight = "20px";
      date.textContent = Vue.moment().format("DD/MM/YYYY");

      area.appendChild(names);
      area.appendChild(date);

      // añadir el area al primer elemento de config.elements
      const first_element = document.getElementById(this.config.elements[0].id);
      first_element.insertBefore(area, first_element.firstChild);
      first_element.dataset.original_position = getComputedStyle(
        first_element
      ).display;
      first_element.style.display = "block";

      pdf = await html2canvas(area, {
        scale: 2,
        useCORS: true,
        windowWidth: document.documentElement.scrollWidth,
        windowHeight: document.documentElement.scrollHeight
      })
        .then(canvas => {
          const scale = pdf_width / canvas.width;
          const canvas_height = canvas.height * scale;

          // Capturar la sección de la tabla que corresponde al header
          const section_canvas = document.createElement("canvas");
          section_canvas.width = canvas.width;
          section_canvas.height = 50;

          const section_ctx = section_canvas.getContext("2d");
          section_ctx.drawImage(
            canvas,
            0,
            0,
            canvas.width,
            50,
            0,
            0,
            canvas.width,
            50
          );

          pdf.addImage(
            canvas.toDataURL("image/jpeg", 0.7),
            "JPEG",
            5, // Margen izquierdo
            5, // Margen superior
            pdf_width,
            canvas_height
          );
          return pdf;
        })
        .catch(error => {
          Utils.showError(this, "something_went_wrong_header_pdf");
        });

      area.remove();
      if (first_element.dataset.original_position) {
        first_element.style.position = first_element.dataset.original_position;
        delete first_element.dataset.original_position;
      }

      return pdf;
    },
    async generateTablePDF(area, scale_canvas, pdf, has_header) {
      area = area.querySelector("table");
      // Buscar y desactivar temporalmente todos los elementos con position sticky dentro de la tabla
      const sticky_elements = area.querySelectorAll(
        "thead th, tbody tr, tbody td"
      );
      sticky_elements.forEach(element => {
        if (getComputedStyle(element).position === "sticky") {
          element.dataset.original_position = getComputedStyle(
            element
          ).position;
          if (element.tagName === "TH") {
            element.style.position = "static";
          } else {
            element.style.position = "relative";
          }
        }
      });

      // Utiliza html2canvas para capturar el área completa (incluyendo el contenido con scroll)
      return await html2canvas(area, {
        scale: scale_canvas, // Aumenta la calidad de la imagen capturada
        useCORS: true, // Para evitar problemas de cross-origin con imágenes externas
        windowWidth: document.documentElement.scrollWidth, // Captura el ancho completo con scroll
        windowHeight: document.documentElement.scrollHeight // Captura la altura completa con scroll
      })
        .then(canvas => {
          const pdf_width = pdf.internal.pageSize.getWidth() - 10; // Margen de 10mm a cada lado
          let pdf_height = pdf.internal.pageSize.getHeight() - 10; // Margen de 10mm a cada lado

          const scale = pdf_width / canvas.width;
          let position_y = 0;

          // Resta la altura del header si exiiste en la primera pagina solamente
          if (has_header) {
            pdf_height -= 12;
            position_y += 12;
          }

          const rows = area.querySelectorAll("thead tr, tbody tr");
          rows.forEach((row, index) => {
            const row_rect = row.getBoundingClientRect();
            const row_height = row_rect.height * scale_canvas;
            const row_height_scaled = row_height * scale;

            // Verificar si la fila cabe en la página actual
            if (position_y + row_height_scaled > pdf_height) {
              pdf.addPage();
              if (has_header) {
                pdf_height += 12;
              }
              position_y = 0;
            }

            // Capturar la sección de la tabla que corresponde a esta fila
            const section_canvas = document.createElement("canvas");
            section_canvas.width = canvas.width;
            section_canvas.height = row_height;

            const section_ctx = section_canvas.getContext("2d");
            section_ctx.drawImage(
              canvas,
              0,
              row_rect.top * scale_canvas -
                area.getBoundingClientRect().top * scale_canvas,
              canvas.width,
              row_height,
              0,
              0,
              canvas.width,
              row_height
            );

            // Añadir la imagen de la fila al PDF
            const section_image_data = section_canvas.toDataURL(
              "image/jpeg",
              0.7
            );
            pdf.addImage(
              section_image_data,
              "JPEG",
              5, // Margen izquierdo
              5 + position_y, // Margen superior
              pdf_width,
              row_height_scaled
            );

            position_y += row_height_scaled;
          });

          // Restaurar el estilo sticky si existía
          sticky_elements.forEach(element => {
            if (element.dataset.original_position) {
              element.style.position = element.dataset.original_position;
              delete element.dataset.original_position;
            }
          });

          return pdf;
        })
        .catch(error => {
          Utils.showError(this, "something_went_wrong_table_pdf");
        });
    },
    async generateElementPDF(area, scale_canvas, pdf, has_header) {
      return await html2canvas(area, {
        scale: scale_canvas,
        useCORS: true,
        allowTaint: true
      })
        .then(canvas => {
          const pdf_width = pdf.internal.pageSize.getWidth() - 10;
          let pdf_height = pdf.internal.pageSize.getHeight() - 10;
          let position_y = 0;

          if (has_header) {
            pdf_height -= 12;
            position_y += 12;
          }

          const canvas_ratio = canvas.width / canvas.height;
          const pdf_ratio = pdf_width / pdf_height;

          let img_width, img_height;

          if (canvas_ratio > pdf_ratio) {
            img_width = pdf_width;
            img_height = pdf_width / canvas_ratio;
          } else {
            img_height = pdf_height;
            img_width = pdf_height * canvas_ratio;
          }

          pdf.addImage(
            canvas.toDataURL("image/jpeg", 0.7),
            "JPEG",
            5, // Align to the left
            5 + position_y, // Align to the top
            img_width,
            img_height
          );

          return pdf;
        })
        .catch(error => {
          Utils.showError(this, "something_went_wrong_element_pdf");
        });
    }
  }
};
</script>

<style lang="scss">
.a-btn-pdf {
  .v-overlay {
    height: 100vh;
    width: 100vw;
    .v-overlay__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
